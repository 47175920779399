import * as actionTypes from '../../actions/parent/parentActions'

const initialState = {
    parents: [],
    parent: '',
}
const parents = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_PARENTS:
            return {
                ...state,
                parents: action.value,
                parent:'awais'
            }
        case actionTypes.EDIT_PARENT:
            return {
                ...state,
                parent: action.value
            }
        case actionTypes.ADD_PARENT:
            return {
                ...state,
                parents: state.parents.concat(action.value)
            }
        case actionTypes.DELETE_PARENT:
            const updatedArray=state.parents.filter(parent=> parent.key!==action.value)
            return {
                ...state,
                parents: updatedArray
            }
    }
    return state
}
export default parents
