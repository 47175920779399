import * as actionTypes from '../../actions/role/roleActions'

const initialState = {
    roles: [],
    role: '',
}
const roles = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_ROLES:
            return {
                ...state,
                roles: action.value,
                role:''
            }
        // case actionTypes.EDIT_PARENT:
        //     return {
        //         ...state,
        //         parent: action.value
        //     }
        // case actionTypes.ADD_PARENT:
        //     return {
        //         ...state,
        //         parents: state.parents.concat(action.value)
        //     }
        // case actionTypes.DELETE_PARENT:
        //     const updatedArray=state.parents.filter(parent=> parent.key!==action.value)
        //     return {
        //         ...state,
        //         parents: updatedArray
        //     }
    }
    return state
}
export default roles
