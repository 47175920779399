import React from "react";
import * as Icon from "react-feather";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as ImIcons from "react-icons/im";
import * as BsIcons from "react-icons/bs";
import * as GiIcons from "react-icons/gi";
import * as HiIcons from "react-icons/hi";
import * as GoIcons from "react-icons/go";
// import IoIosMan from 'react-icons/io'
// import ImManWoman from 'react-icons/im'
// import RiDashboardFill from 'react-icons/ri'
// import BsClipboard from 'react-icons/bs'
// import FaChalkboardTeacher from 'react-icons/fa'
// import GoChecklist from 'react-icons/go'

const navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <RiIcons.RiDashboardFill size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },

  {
    id: "student",
    title: "Student",
    type: "item",
    icon: <IoIcons.IoIosMan size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/student",
  },

  {
    id: "parents",
    title: "Parents",
    type: "item",
    icon: <ImIcons.ImManWoman size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/parents",
  },

  {
    id: "teacher",
    title: "Teacher",
    type: "item",
    icon: <GiIcons.GiTeacher size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/teacher",
  },

  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.Users size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/users",
  },

  {
    id: "academic",
    title: "Academic",
    type: "collapse",
    icon: <HiIcons.HiAcademicCap size={15} />,
    children: [
      {
        id: "class",
        title: "Class",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/class",
      },
      {
        id: "section",
        title: "Section",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/section",
      },
      {
        id: "subject",
        title: "Subject",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/subject",
      },
      {
        id: "syllabus",
        title: "Syllabus",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/syllabus",
      },
      {
        id: "assignment",
        title: "Assignment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/assignment",
      },
      {
        id: "routine",
        title: "Routine",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/routine",
      },
      // {
      //   id: "zoomliveclass",
      //   title: "Zoom Live Class",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/zoomliveclass"
      // },
      {
        id: "tutorial",
        title: "Tutorial",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tutorial",
      },
    ],
  },

  {
    id: "attendance",
    title: "Attendance",
    type: "collapse",
    icon: <BsIcons.BsClipboard size={15} />,
    children: [
      {
        id: "studentattendance",
        title: "Student Attendance",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sattendance",
      },
      {
        id: "teacherattendance",
        title: "Teacher Attendance",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tattendance",
      },
      {
        id: "userattendance",
        title: "User Attendance",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/uattendance",
      },
    ],
  },

  {
    id: "exam",
    title: "Exam",
    type: "collapse",
    icon: <IoIcons.IoIosPaper size={15} />,
    children: [
      {
        id: "exam",
        title: "Exam",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/exam",
      },
      {
        id: "examattendance",
        title: "Exam Attendance",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/examattendance",
      },
      {
        id: "grade",
        title: "Grade",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/grade",
      },
    ],
  },

  {
    id: "mark",
    title: "Mark",
    type: "collapse",
    icon: <GoIcons.GoChecklist size={15} />,
    children: [
      {
        id: "mark",
        title: "Mark",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/mark",
      },
      {
        id: "markdistribution",
        title: "Mark Distribution",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/markdistribution",
      },
      {
        id: "promotion",
        title: "Promotion",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/promotion",
      },
      {
        id: "markcomment",
        title: "Mark Comment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/mark/comment",
      },
      {
        id: "skills",
        title: " Skills and Behaviours",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/skills",
      },
    ],
  },
  // {
  //   id: "email",
  //   title: "Email",
  //   type: "item",
  //   icon: <Icon.Mail size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/email/:filter",
  //   filterBase: "/email/inbox",
  // },
  {
    id: "message",
    title: "Message",
    type: "item",
    icon: <Icon.Users size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/conversation",
  },
  {
    id: "mailsms",
    title: "Mail / SMS",
    type: "item",
    icon: <Icon.Users size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/mailandsms",
  },
  {
    id: "onlineexam",
    title: "Online Exam",
    type: "collapse",
    icon: <FaIcons.FaChalkboardTeacher size={15} />,
    children: [
      {
        id: "questiongroup",
        title: "Question Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/question_group",
      },
      {
        id: "questionlevel",
        title: "Question Level",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/question_level",
      },
      {
        id: "questionbank",
        title: "Question Bank",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/question_bank",
      },
      {
        id: "onlineexam",
        title: "Online Exam",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/online_exam",
      },
      {
        id: "instruction",
        title: "Instruction",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/instruction",
      },
      {
        id: "takeexam",
        title: "Take Exam",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/take_exam",
      },
    ],
  },

  {
    id: "payroll",
    title: "Payroll",
    type: "collapse",
    icon: <FaIcons.FaChalkboardTeacher size={15} />,
    children: [
      {
        id: "salarytemplate",
        title: "Salary Template",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/salary_template",
      },
      {
        id: "parttimetemplate",
        title: "Part Time Template",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/parttime_template",
      },
      {
        id: "managesalary",
        title: "Manage Salary",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/manage_salary",
      },
      {
        id: "makepaymant",
        title: "Make Payment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/make_payment",
      },
      {
        id: "overtime",
        title: "Overtime",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/overtime",
      },
    ],
  },

  {
    id: "leaveapplication",
    title: "Leave Application",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "leavecategory",
        title: "Leave Category",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/leavecategory",
      },
      {
        id: "leaveassign",
        title: "Leave Assign",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/leaveassign",
      },
      {
        id: "leaveapply",
        title: "Leave Apply",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/leaveapply",
      },
      {
        id: "leaveapplication",
        title: "Leave Application",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/leaveapplication",
      },
    ],
  },

  {
    id: "assetmanagement",
    title: "Asset Management",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "vender",
        title: "Vender",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/vendor",
      },
      {
        id: "location",
        title: "Location",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/location",
      },
      {
        id: "assetcategory",
        title: "Asset Category",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/assetcategory",
      },
      {
        id: "asset",
        title: "Asset",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/asset",
      },
      {
        id: "assetassignment",
        title: "Asset Assignment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/asset_assignment",
      },
      {
        id: "purchase",
        title: "Purchase",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/purchase",
      },
    ],
  },
  // {
  //   id: "gmeetliveclass",
  //   title: "Gmeet Live Class",
  //   type: "item",
  //   icon: <ImIcons.ImManWoman size={15} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/gmeetliveclass",
  // },
  {
    id: "child",
    title: "Child",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "child",
        title: "Child Care",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/childcare",
      },
    ],
  },
  {
    id: "library",
    title: "Library",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "book",
        title: "Books",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/book",
      },
      {
        id: "issue",
        title: "Issue",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/issue",
      },
      {
        id: "ebooks",
        title: "E-Library",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/elibrary",
      },
      {
        id: "member",
        title: "Member",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/member",
      },
    ],
  },

  {
    id: "transport",
    title: "Transport",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "transport",
        title: "Transport",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/transport",
      },
      {
        id: "member",
        title: "Member",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/member",
      },
    ],
  },

  {
    id: "hostel",
    title: "Hostel",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "hostel",
        title: "Hostel",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/hostel",
      },
      {
        id: "category",
        title: "Category",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/category",
      },
      {
        id: "member",
        title: "Member",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/member",
      },
    ],
  },

  {
    id: "account",
    title: "Account",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "feetypes",
        title: "Fee Types",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/feetypes",
      },
      {
        id: "invoice",
        title: "Invoice",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/invoice",
      },
      {
        id: "paymenthistory",
        title: "Payment History",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/paymenthistory",
      },
      {
        id: "expense",
        title: "Expense",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/expense",
      },
      {
        id: "income",
        title: "Income",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/income",
      },
    ],
  },

  {
    id: "announcement",
    title: "Announcement",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "notice",
        title: "Notice",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/notice",
      },
      {
        id: "event",
        title: "Event",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/event",
      },
      {
        id: "holiday",
        title: "Holiday",
        type: "item",
        icon: <ImIcons.ImManWoman size={15} />,
        permissions: ["admin", "editor"],
        navLink: "/holiday",
      },
    ],
  },
  {
    id: "report",
    title: "Report",
    type: "collapse",
    icon: <GoIcons.GoChecklist size={15} />,
    children: [
      {
        id: "classreport",
        title: "Class Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/classreport",
      },
      {
        id: "studentreport",
        title: "Student Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/studentreport",
      },
      {
        id: "routinereport",
        title: "Routine Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/routinereport",
      },
      {
        id: "attendancereport",
        title: "Attendance Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/attendancereport",
      },
      {
        id: "attendanceoverviewreport",
        title: "Attendance Overview Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/attendanceoverviewreport",
      },
      {
        id: "terminalreport",
        title: "Terminal Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/terminalreport",
      },
      {
        id: "meritstagereport",
        title: "Merit Stage Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/meritstagereport",
      },
      {
        id: "tabulationsheetreport",
        title: "Tabulation Sheet Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tabulationsheetreport",
      },
      {
        id: "progresscardreport",
        title: "Progress Card Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/progresscardreport",
      },
      {
        id: "librarybooksreport",
        title: "Library Books Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/librarybooksreport",
      },
      {
        id: "librarybookissuereport",
        title: "Library Book Issue Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/librarybookissuereport",
      },
      {
        id: "onlineexamreport",
        title: "Online Exam Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/onlineexamreport",
      },
      {
        id: "onlineexamquestionreport",
        title: "Online Exam Question Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/onlineexamquestionreport",
      },
      {
        id: "onlineexamquestionanswerreport",
        title: "Online Exam Question Answer Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/onlineexamquestionanswerreport",
      },
      {
        id: "onlineadmissionreport",
        title: "Online Admission Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/onlineadmissionreport",
      },
      {
        id: "searchpaymentfeesreport",
        title: "Search Payment Fees Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/searchpaymentfeesreport",
      },
      {
        id: "feesreport",
        title: "Fees Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/feesreport",
      },
      {
        id: "duefeesreport",
        title: "Due Fees Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/duefeesreport",
      },
      {
        id: "balancefeesreport",
        title: "Balance Fees Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/balancefeesreport",
      },
      {
        id: "transactionreport",
        title: "Transaction Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/transactionreport",
      },
      {
        id: "overtimereport",
        title: "Overtime Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/overtimereport",
      },
      {
        id: "salaryreport",
        title: "Salary Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/salaryreport",
      },
      {
        id: "accountledgerreport",
        title: "Account Ledger Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/accountledgerreport",
      },
    ],
  },
  {
    id: "onlineadmission",
    title: "Online Admission",
    type: "item",
    icon: <IoIcons.IoIosMan size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/onlineadmission",
  },

  {
    id: "visitor",
    title: "Visitor",
    type: "item",
    icon: <IoIcons.IoIosMan size={15} />,
    permissions: ["admin", "editor"],
    navLink: "/visitor",
  },

  {
    id: "administration",
    title: "Administration",
    type: "collapse",
    icon: <RiIcons.RiAdminLine size={15} />,
    children: [
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/roles",
      },
      {
        id: "academivc year",
        title: "Academic Year",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/academicyear",
      },
      {
        id: "student group",
        title: "Student Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/studentgroup",
      },
      {
        id: "activatebirthday",
        title: "Activate Birthday",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/activatebirthday",
      },
      {
        id: "complain",
        title: "Complain",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/complain",
      },

      {
        id: "system admin",
        title: "System Admin",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/systemadmin",
      },
      {
        id: "alumni",
        title: "Alumni",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/alumni",
      },
      {
        id: "activitylog",
        title: "Activity Log",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/activitylog",
      },
      {
        id: "sendlogin",
        title: "Send Login",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sendlogin",
      },
      {
        id: "reset password",
        title: "Reset Password",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/resetpassword",
      },
      {
        id: "smstemplate",
        title: "Mail / SMS Template",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/mailandsmstemplate",
      },
      {
        id: "import",
        title: "Import",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/import",
      },
      {
        id: "backup",
        title: "Backup",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/backup",
      },
    ],
  },

  {
    id: "setting",
    title: "Settings",
    type: "collapse",
    icon: <IoIcons.IoIosMan size={15} />,
    children: [
      {
        id: "general setting",
        title: "General Setting",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsetting",
      },
      {
        id: "frontend setting",
        title: "FrontEnd Setting",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/frontendsetting",
      },
      {
        id: "mark setting",
        title: "Mark setting",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/marksetting",
      },
      {
        id: "zoom settings",
        title: "Zoom Setting",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/zoomsetting",
      },
    ],
  },
];

export default navigationConfig;
