import axios from "axios";
// import ReactTimeAgo from "react-time-ago";
import {React} from "react";

export const STORE_ROLES = 'STORE_ROLES'

export const saveRoles = (res) => {
    return {
        type: 'STORE_ROLES',
        value: res
    }
}

//Store Collections
export const storeRoles = () => {
    return dispatch => {
        axios.get('/roles')
            .then(response => {
                console.log(response)
                const results = response.data.roles.map(row => ({
                    id:row.id,
                    name: row.name,
                    // createdAt: <ReactTimeAgo date={new Date(row.createdAt)} locale="en-US"/>

                }))
                dispatch(saveRoles(results))

            })
    }

}
// save single brand
export const saveRole = (res) => {
    return {
        type: 'ADD_ROLE',
        value: res
    }
}

