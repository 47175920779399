import axios from "axios";
// import ReactTimeAgo from "react-time-ago";
import {React} from "react";

export const STORE_USERS = 'STORE_USERS'
export const ADD_USER='ADD_USER'
export const EDIT_USER='EDIT_USER'
export const DELETE_USER='DELETE_USER'
export const saveUsers = (res) => {
    return {
        type: 'STORE_USERS',
        value: res
    }
}
export const editUser = (res) => {
    return {
        type: 'EDIT_USER',
        value: res
    }
}
export const deleteUser = (res) => {
    return {
        type: 'DELETE_USER',
        value: res
    }
}
//Store Collections
export const storeUsers = () => {
    return dispatch => {
        axios.get('/users')
            .then(response => {
                console.log(response)
                const results = response.data.users.map(row => ({
                    id:row.id,
                    name: row.name,
                    email: row.email,
                    role: row.roles[0].name
                    // createdAt: <ReactTimeAgo date={new Date(row.createdAt)} locale="en-US"/>

                }))
                dispatch(saveUsers(results))

            })
    }

}
// save single brand
export const saveUser = (res) => {
    return {
        type: 'ADD_USER',
        value: res
    }
}

