import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
  compose
} from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import thunk from 'redux-thunk'

import storage from "redux-persist/lib/storage";

// import brands from "./../store/reducers/brands";
// import products from "./../store/reducers/products";

import {applyMiddleware} from "redux";
import rootReducer from "../reducers/rootReducer"

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers=window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
      serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
  },
      composeEnhancers(applyMiddleware(thunk))),
});

export {store};

// import { createStore, applyMiddleware, compose } from "redux"
// import createDebounce from "redux-debounced"
// import thunk from "redux-thunk"
// import rootReducer from "../reducers/rootReducer"

// const middlewares = [thunk, createDebounce()]

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const store = createStore(
//   rootReducer,
//   {},
//   composeEnhancers(applyMiddleware(...middlewares))
// )

// export { store }
