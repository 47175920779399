import axios from "axios";
// import ReactTimeAgo from "react-time-ago";
import {React} from "react";

export const STORE_PARENTS = 'STORE_PARENTS'
export const ADD_PARENT='ADD_PARENT'
export const EDIT_PARENT='EDIT_PARENT'
export const DELETE_PARENT='DELETE_PARENT'
export const saveParents = (res) => {
    return {
        type: 'STORE_PARENTS',
        value: res
    }
}
export const editParent = (res) => {
    return {
        type: 'EDIT_PARENT',
        value: res
    }
}
export const deleteParent = (res) => {
    return {
        type: 'DELETE_PARENT',
        value: res
    }
}
//Store Collections
export const storeParents = () => {
    return dispatch => {
        axios.get('/parents')
            .then(response => {
                console.log(response)
                const results = response.data.parents.map(row => ({
                    id:row.id,
                    guardianname: row.guardian_name,
                    name: row.name,
                    email: row.email
                    // createdAt: <ReactTimeAgo date={new Date(row.createdAt)} locale="en-US"/>

                }))
                dispatch(saveParents(results))

            })
    }

}
// save single brand
export const saveParent = (res) => {
    return {
        type: 'ADD_PARENT',
        value: res
    }
}

