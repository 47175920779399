import axios from "axios";
// import ReactTimeAgo from "react-time-ago";
import {React} from "react";

export const STORE_MODULES = 'STORE_MODULES'

export const saveModules = (res) => {
    return {
        type: 'STORE_MODULES',
        value: res
    }
}

//Store Collections
export const storeModules = () => {
    return dispatch => {
        axios.get('/module/show/')
            .then(response => {
                console.log(response)
                const results = response.data.modules.map(row => ({
                    name: row.name,
                    // createdAt: <ReactTimeAgo date={new Date(row.createdAt)} locale="en-US"/>

                }))
                dispatch(saveModules(results))

            })
    }

}
// save single brand
export const saveModule = (res) => {
    return {
        type: 'ADD_MODULE',
        value: res
    }
}