import * as actionTypes from '../../actions/module/moduleActions'

const initialState = {
    modules: [],
    module: '',
}
const modules = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_MODULES:
            return {
                ...state,
                modules: action.value,
                module:''
            }
        // case actionTypes.EDIT_PARENT:
        //     return {
        //         ...state,
        //         parent: action.value
        //     }
        // case actionTypes.ADD_PARENT:
        //     return {
        //         ...state,
        //         parents: state.parents.concat(action.value)
        //     }
        // case actionTypes.DELETE_PARENT:
        //     const updatedArray=state.parents.filter(parent=> parent.key!==action.value)
        //     return {
        //         ...state,
        //         parents: updatedArray
        //     }
    }
    return state
}
export default modules
