import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import parents from "./parent/parents";
import roles from "./role/roles";
import modules from "./module/modules";
import users from "./user/users";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  parents: parents,
  roles: roles,
  modules: modules,
  users: users,
});

export default rootReducer;
